import EventTickets from '@/util/EventTickets';
import { distributor } from '@/settings/Roles';

export default {
	data() {
		return {
			eventTickets: new EventTickets(),
			distributor,
		};
	},
	computed: {
		freeEvents() {
			try {
				return this.eventTickets.data.response.data.response.events;
			} catch (error) {
				return [];
			}
		},
	},
	created() {
		if (this.$user.auth() && [this.distributor].includes(this.$user.details().type) && !this.isAgencySite) {
			this.eventTickets.getFreeEventTickets();
		}
	},
};

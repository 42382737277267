var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",style:({
			background: ("url(" + _vm.S3_PATH + "/assets/products/" + _vm.theme + "/backgrounds/" + _vm.eventCodeName + "_store.png)"),
			'background-position': 'right',
			'background-size': 'cover',
			'background-repeat': 'no-repeat',
			'padding-top': '4rem',
			'padding-bottom': '4rem',
		})},[_c('div',{staticClass:"row container mx-auto p-0"},[_c('div',{staticClass:"col-md-7 col-sm-12 py-2 d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1"},[_c('h3',{staticClass:"text-white",staticStyle:{"font-size":"3rem"}},[_c('b',[_vm._v(_vm._s(_vm.translate(_vm.eventCodeName)))])]),_c('p',{staticClass:"text-justify text-white mt-2",domProps:{"innerHTML":_vm._s(_vm.translate((_vm.eventCodeName + "_description")))}})])]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-md-4 col-xs-12 text-right pt-4"},[_c('div',{staticClass:"btn btn-light text-primary p-2 mt-3 ml-2 pointer event-custom-btn custom-primary-btn",staticStyle:{"font-weight":"600"},on:{"click":function($event){return _vm.$router.push({ name: 'FreeEventsDetails', params: { eventCodeName: _vm.eventCodeName } })}}},[_vm._v(" "+_vm._s(_vm.translate('free_event_see_more'))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
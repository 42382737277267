<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row">
		<div
			:style="{
				background: `url(${S3_PATH}/assets/products/${theme}/backgrounds/${eventCodeName}_store.png)`,
				'background-position': 'right',
				'background-size': 'cover',
				'background-repeat': 'no-repeat',
				'padding-top': '4rem',
				'padding-bottom': '4rem',
			}"
			class="col-12">
			<div class="row container mx-auto p-0">
				<div class="col-md-7 col-sm-12 py-2 d-flex flex-column">
					<div class="flex-grow-1">
						<h3
							class="text-white"
							style="font-size: 3rem">
							<b>{{ translate(eventCodeName) }}</b>
						</h3>
						<p
							class="text-justify text-white mt-2"
							v-html="translate(`${eventCodeName}_description`)" />
					</div>
				</div>
				<div class="col-1" />
				<div class="col-md-4 col-xs-12 text-right pt-4">
					<div
						style="font-weight: 600;"
						class="btn btn-light text-primary p-2 mt-3 ml-2 pointer event-custom-btn custom-primary-btn"
						@click="$router.push({ name: 'FreeEventsDetails', params: { eventCodeName: eventCodeName } })">
						{{ translate('free_event_see_more') }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import { S3_PATH } from '@/settings/Images';
import { FreeEvents } from '@/translations';

export default {
	name: 'FreeTicketsBanner',
	// components: { NumberInputSpinner },
	mixins: [WindowSizes],
	messages: [FreeEvents],
	props: {
		eventCodeName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
		};
	},
};
</script>
<style scoped>
.event-custom-btn{
	font-weight: 600;
	width: 200px;
	height: 50px;
	padding-top: 0.7rem !important;
	text-align: center;
	border-radius: 12px;
	font-size: 1.08rem;
}
</style>
